import { effect, Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';

export interface AppConfig {
  inputStyle: string;
  colorScheme: string;
  theme: string;
  ripple: boolean;
  menuMode: string;
  scale: number;
}

interface LayoutState {
  staticMenuDesktopInactive: boolean;
  overlayMenuActive: boolean;
  profileSidebarVisible: boolean;
  configSidebarVisible: boolean;
  staticMenuMobileActive: boolean;
  menuHoverActive: boolean;
}

@Injectable({ providedIn: 'root' })
export class LayoutService {
  private _overlayOpen = new Subject<null>();
  private _configUpdate = new Subject<AppConfig>();
  private _config: AppConfig = {
    ripple: false,
    inputStyle: 'outlined',
    menuMode: 'static',
    colorScheme: 'light',
    theme: 'light',
    scale: 16,
  };

  overlayOpen$ = this._overlayOpen.asObservable();
  configUpdate$ = this._configUpdate.asObservable();

  state: LayoutState = {
    staticMenuDesktopInactive: true,
    overlayMenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
  };

  config = signal<AppConfig>(this._config);

  constructor() {
    effect(() => {
      const config = this.config();
      if (this.updateStyle(config)) {
        this.changeTheme();
      }
      this.changeScale(config.scale);
      this.onConfigUpdate();
    });
  }

  updateStyle(config: AppConfig): boolean {
    return (
      config.theme !== this._config.theme ||
      config.colorScheme !== this._config.colorScheme
    );
  }

  onMenuToggle(): void {
    if (this.isOverlay()) {
      this.state.overlayMenuActive = !this.state.overlayMenuActive;
      if (this.state.overlayMenuActive) {
        this._overlayOpen.next(null);
      }
    }

    if (this.isDesktop()) {
      this.state.staticMenuDesktopInactive =
        !this.state.staticMenuDesktopInactive;
    } else {
      this.state.staticMenuMobileActive =
        !this.state.staticMenuMobileActive;

      if (this.state.staticMenuMobileActive) {
        this._overlayOpen.next(null);
      }
    }
  }

  showProfileSidebar(): void {
    this.state.profileSidebarVisible = !this.state.profileSidebarVisible;
    if (this.state.profileSidebarVisible) {
      this._overlayOpen.next(null);
    }
  }

  showConfigSidebar(): void {
    this.state.configSidebarVisible = true;
  }

  isOverlay(): boolean {
    return this.config().menuMode === 'overlay';
  }

  isDesktop(): boolean {
    return window.innerWidth > 991;
  }

  isMobile(): boolean {
    return !this.isDesktop();
  }

  onConfigUpdate(): void {
    this._config = { ...this.config() };
    this._configUpdate.next(this.config());
  }

  changeTheme(): void {
    const config = this.config();
    const themeLink = document.getElementById('theme-css') as HTMLLinkElement;
    const themeLinkHref = themeLink.getAttribute('href')!;
    const newHref = themeLinkHref
      .split('/')
      .map(el => el === this._config.theme
        ? config.theme
        : el === `theme-${this._config.colorScheme}`
          ? `theme-${config.colorScheme}`
          : el)
      .join('/');

    this.replaceThemeLink(newHref);
  }

  replaceThemeLink(href: string): void {
    const id = 'theme-css';
    let themeLink = document.getElementById(id) as HTMLLinkElement;
    const cloneLinkElement = themeLink.cloneNode(true) as HTMLLinkElement;

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    themeLink.parentNode!.insertBefore(
      cloneLinkElement,
      themeLink.nextSibling,
    );
    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove();
      cloneLinkElement.setAttribute('id', id);
    });
  }

  changeScale(value: number): void {
    document.documentElement.style.fontSize = `${value}px`;
  }

  getMobileOS(): string {
    const ua = navigator.userAgent;

    if (/android/i.test(ua)) {
      return 'android';
    } else if (/iPad|iPhone|iPod/.test(ua) ||
      /Mac/i.test(ua) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) {
      return 'ios';
    }

    return 'other';
  }
}
